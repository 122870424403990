body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

Button{
  width: 150px;
  height: 40px;
  border-radius: 25px;
  background-color: #f5ecec;
  font-family: 'DM Sans', sans-serif;
  font-size: 15px;
  z-index: 10;
}

p { margin:0 }
  
footer{
  background-color: #000000;
  position: relative;
  height: 30px;
  text-align: center;
  color: white;
  padding: 5px;
  font-family: 'DM Sans', sans-serif;
}
.footer-link {
  color: inherit; /* Keeps the color consistent with surrounding text */
  text-decoration: none; /* Removes underline from the link */
}

.footer-link:hover {
  text-decoration: underline; /* Adds underline on hover */
}
main{
  font-family: 'DM Sans', sans-serif;
  position: relative;
  padding-top: 60px;
}
/* -------------POPUP ------------*/
.PopUpContainer{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);  /* Dim background for the pop-up */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; 
}
.PopUpBackground{
  background-image: url("../public/static/AlbertoQ0.webp");
  background-position: center;
  background-repeat: no-repeat;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  height: 600px;
  width: 400px;
  align-content: center;
}
.flexCloseButton{
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}
.closeButton{
  width: 30px;
  height: 25px;
  background-color: rgb(80, 79, 79);
}
.whitecontainerpopUp{
  background-color: #ffffff;
  opacity: 0.7;
  height: 580px;
}
.contentPopUp{
  font-size: 40px;
  text-shadow: 2px 2px #c0bebe;
  display: flex;
  flex-direction: column;
  padding-top: 45%;
  gap: 100px;
}
  /* -------------NAVBAR ------------*/
  .navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #000000;
    padding: 1rem 2rem;
    font-family: 'DM Sans', sans-serif;
    position: fixed;
    z-index: 3;
    width: 100vw;
}

.socialNav {
    display: flex;
    gap: 20px;
    padding-right: 70px;
}

.socialIcon {
    color: white;
    font-size: 1.5rem;
    text-decoration: none;
    transition: color 0.3s;
}

.socialIcon:hover {
    color: #ddd;
}

.Navlinks {
    list-style: none;
    display: flex;
    flex-direction: row; 
}

.Navlinks li {
    margin: 0 15px;
}


.Navlinks li a {
    color: white;
    text-decoration: none;
    font-size: 1.1rem;
    transition: color 0.3s;
}

.Navlinks li a:hover {
    color: #ddd;
}


/* -------------HOMEPAGE ------------*/

.HomePage {
  width: 100%;
}
.firstimageContainer{
  background-image: url("../public/static/AlbertoQ4.webp");
  background-size:cover; 
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  position: sticky;
  overflow: hidden !important;
  top: 63px;
}

.titleHome{
  z-index: 10;
  text-align: center;
  color: white; 
}
.OnlineTitle{
  font-size: 120px;
  font-family: 'Great Vibes';
}
.buttomPlacement{
  display: flex;
  justify-content: center; 
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: row;
  gap: 50px;
}
.CostumeButton{
  display: flex;
  width: 150px;
  height: 30px;
  padding: 10px;
  border: 3px solid #ffffff;
  font-family: 'DM Sans';
  font-weight: 500;
  color: white;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 10;
  border-radius: 25px;
}
.CostumeButtonBlack{
  display: flex;
  width: 150px;
  height: 30px;
  padding: 10px;
  border: 3px solid black;
  font-family: 'DM Sans';
  font-weight: 500;
  color: black;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 10;
  border-radius: 25px;
}
.CostumeButton:hover{
  background-color: #ffffff;
  transition: all 0.3s;
  color: #000000;
}

.divspace{
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: white;
}

.fotospace{
  width: 100%;
}

.fotoedit1{
  height: 450px;
  width: 300px;
  float: right;
}
.flexdiv{
  width:100%;
  justify-content: center;
  padding-left: 50px;
}
.Thankyou{
  font-size: 40px;
  font-weight: 800; 
  padding-bottom: 30px; 
}
.Fitnessdescription{
  font-size: 20px;
  width: 30vw;
}
.secondimageContainer {
  background-image: url("../public/static/AlbertoQ5.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  width: 100%;
  height:80vh;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
}
.secondsubtitle{
  padding: 10%;
  z-index: 2;
  text-align: center;
  color: white; 
  font-size: x-large;
  margin-left: 100px;
  margin-right: 100px;
} 
.ThirdimageContainer{
  height: 80vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: white;
  position: relative;
}
.grayboxindex{
  background-color: rgb(172, 171, 171);
  width: 400px;
  height: 280px;
  align-content: center;  
  z-index: 2;
  right: -50px;
  bottom: -80px;
  position: relative;
  text-align: center;
  box-shadow: 5px 5px 5px rgb(131, 142, 146);
}
.grayboxfont{
  margin: 20px;
  font-size: 20px;

}

.fotoindex2{
  background-image: url("../public/static/mediaAQ4.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 400px;
  height: 280px;
  top: -80px;
  position: relative;
}
.backgrountestimonials{
  background-color: white;
  position: relative;
  padding-top: 30px;
  height: 60vh;
}
.Testimonialscontainer {
  width: 72%;
  overflow: hidden; /* Only show one testimonial at a time */
  margin: 0 auto;
  height: 55vh;
  will-change: transform;
}
.Testimonialsfont {
  font-weight: 600;
  font-size: 20px;
  text-align: center;
}

.Testimonialsunderline {
  width: 80vw;
  background-color: black;
  height: 2px;
  margin: 0 auto;
  }

.TestimonialsSlider {
  display: flex;
  width: 100%;
}

.TestimonialsWrapper {
  display: flex;
  transition: transform 0.3s ease-in-out;
  align-items: center;
}

.Testimonialdisplay {
  min-width: 100%; 
  text-align: center;
  transition: 0.5s ease-in-out;
  font-size: 1.2rem;
  padding-top: 15%;
  
}

/* -------------PROGRAM PAGE ------------*/

.ProgramPage{
  position: relative;
  width: 100%;
}

.programTitlecontainer{
  display: flex;
  flex-direction: row;
  height: 35vh;
}

.imageContainer {
  position: relative;
}


.overlayText {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  text-align: center;
  height: 100%;
  width: 100%;
  color: white;
}
.textoverlay{
  padding: 10%;
  font-size: 20px
}
.programsubtitle{
  font-weight: 700;
  font-size: 100%;
}
.programheader{
  font-size: 680%;
  font-family:'Great Vibes';
}
.programTitlecontainerright{
  justify-content: flex-end;
  text-align: end;
  width: 70%;
  padding-top: 10px;
  padding-right: 20px;
}
.programTitlecontainerleft{
  width: 30%;
  display: flex;
  flex-direction: column;
  padding-top: 80px;
  padding-left: 20px;
  justify-content: flex-end;
}
.ProgramsQuote{
  padding-top: 20px;
  font-size: 130%;
  float: right;
}

.programGrid{
  display: flex;
  flex-direction: column;
  background-color: #ddd;
}
.ProgramGridRowOne{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.GridText{
  text-align: center;
  width: 50%;
}
.ProgramTitle{
  padding-bottom: 20px;
  font-size: 35px;
  font-weight: 500;
}

.coachingImage{
  width: 50vw;
}
.ProgramGridRowTwo{
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 10%;
}
.ProgramGridRowThree{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.SignupGrid{
  text-align: center;
  height: 35vh;
  align-content: center;
  padding: 15%;

}

.icon{
  width: 60px;
  height: 60px;
  padding-bottom: 30px;
}
.iconArrow{
  width: 50px;
  height: 50px;
}
.Signupparagraph{
  font-size: 25px;
  font-weight: 600;
  padding-bottom: 50px;
}
.SignupPaymentProcessGrid{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.SignupProcessGrid{
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 40px;
  padding-bottom: 50px;
  padding-left: 30px;
}
.IconDescription{
  font-size: 20px;
}
.MockupSection{
  display: flex;
  height: 70vh;
  overflow: hidden;
  justify-content: space-around;
}
.priceSection{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.mockuph1{
  padding-left: 15%;
  width: 85%;
}
.iphoneMockup{
  padding-bottom: 10%;
  width: 450px;
  height: 800px;
}
.FourthContrainer{
  background-image: url("../public/static/gym.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: top;
  height: 60vh;
  align-content: center;
  text-align: center;
}
.fouthContainerp{
  padding: 5% 13% 5% 15%;
  font-weight: 700;
  text-shadow: 2px 2px 2px #000; 
  font-size: x-large;
  color: white;
}
.FAQcontainer{
  display: flex;
  flex-direction: column;
  align-content: center;
  padding: 100px 18% 100px 18% ;
  gap: 20px;
}
.FAQTitle{
  font-size: large;
  font-weight: 800;
  padding-bottom: 30px;
}
.FAQ{
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.FAQuestion{
  font-weight: 500;
  transition: color 0.5 ease;
  cursor: pointer;
}

.FAQuestion:hover{
  color:#333;
}
.FAAnswer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s ease, opacity 0.4s ease;
  opacity: 0;
  width: fit-content;
}
.FAAnswer.open {
  max-height: 500px;
  color: #333;
  opacity: 1;
}

/* -------------ABOUT PAGE------------*/
.backgroundBlack{
  background-color: black;
}
.backgroundHeader{
  background-image: url("../public/static/mediaAQ9.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 60vh;
  background-position: center;
  display: flex;
  align-items: flex-end;
  background-position-y: -45px;
}

.HeaderTitle{
  position:absolute;
  color: white; 
  font-size: x-large;
  padding: 40px;
  font-size: 70px;
  font-family: 'Great Vibes';
}
.descriptionSection{
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.AboutDescription{
  color: #ffffff;
  text-align: center;
  width: 70vw;
  font-size: 20px;
}
.ChampionContainer{
  height: 70vh;
  width: 100vw;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.AboutTournamenttitles{
  width: 50%;
  padding: 10%;
}
.ChampionImage{
  opacity: 1;
  display: block;
  height: 100%;
  overflow: hidden;
  width: 50%;
  object-fit: cover;
  padding-left: 10%
}
.ChampionTournamentTitle{
  font-size: 40px;
  color: white;
  text-align: left;
  padding-bottom: 30px;
}
.ChampionTournamentText{
  text-align: center;
  color: #ffffff;
  font-size: 16px;
  text-align: left;
  width: 80%;
}
.descriptionSectiontwo{
  height: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* -------------MOBILE MEDIA------------*/


@media only screen and (max-width: 730px){
  /*-------------HOME PAGE-------------*/
  .Thankyou{
    font-size: 20px;
    font-weight: 800;  
  }
  .Fitnessdescription{
    font-size: 15px;
  }
  .flexdiv{
    padding-top: 80px;
  }
  /*-------------PROGRAM PAGE----------*/
  .programheader{
    font-size: 550%;
    font-family: 'Great Vibes';
  }
  .SignupGrid{
    height: 90vh;
  }
  .MockupSection{
    display: flex;
    height: 70vh;
    overflow: hidden;
  }
  .priceSection{
    display: flex;
    flex-direction: column;
  }
  .iphoneMockup{
    float: right;
    width: 430px;
    height: 600px;
  }
  
  /* ------------ABOUT PAGE ------------*/
  .AboutDescription{
    font-size: 15px;
  }
  .AboutDescriptiontwo{
    font-size: 15px;
  } 
  .ChampionTournamentText{
    font-size: 15px;
  }


  /* ------------FOOTER ------------*/
  footer{
    font-size: 12px;

  }
  
}



@media only screen and (max-width: 430px) {
  .firstimageContainer{
    height: 100vh;
  }
  .OnlineTitle{
    font-size: 75px;
  }
  .buttomPlacement{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 20%;
  }
  .divspace{
    display: flex;
    flex-direction: column-reverse;
  }
  .secondsubtitle{
    padding: 2px;
  }
  .flexdiv{
    padding-left: 30px;
  }
  .Fitnessdescription{
    width: 50vw;
    text-align: justify;
    padding-bottom: 10px;
  }
  .fotospace{
    bottom: 30px;
  }
  .fotoedit1{
    height: 300px;
    width: 200px;
    padding-right: 20px;
  }
  .Thankyou{
    padding-top: 50px;
    font-size: 25px;
    padding-bottom: 50px;
  }
  .secondimageContainer{
    background-attachment: scroll;
  }

  .grayboxindex{
    right: -20px;
    height: 320px;
  }
  .fotoindex2{
    width: 100vw;
    background-position: center;
    right: 40px;
  }
/* ------------PROGRAM PAGE ------------*/
.SignupGrid{
  height: 100vh;

}
.textoverlay{
  padding: 10%;
  font-size: 15px
}
.Signupparagraph{
  font-size: 18px;
}
.ProgramTitle{
  font-size: 25px;
}
.SignupProcessGrid{
  flex-direction: column;
  gap: 20px;
}
.icon{
  width: 50px;
  height: 50px;
  padding-bottom: 30px;
}

.iconArrow{
  width: 50px;
  height: 50px;
  display: flex;
  align-self: center;
  rotate: 90deg;
}

.MockupSection{
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.mockuph1{
  padding-left: 5%;
  text-align: center;
}
.iphoneMockup{
  width: 300px;
  height: 500px;
  padding-left: 15%;
}
.SignupPaymentProcessGrid{
  padding-bottom: 80px;
}

/* ------------ABOUT PAGE ------------*/
.AboutDescription{
  font-size: 15px;
}
.AboutDescriptiontwo{
  font-size: 15px;
}
}